import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

const SearchBarWithButtons = () => {
  const [query, setQuery] = useState('');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const filters = ['Electrician', 'Plumber', 'Mason', 'Painter'];

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get('http://findbackend.jobsparke.com/user');
      setItems(response.data);
      setFilteredItems(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const filterItems = useCallback(() => {
    const filtered = items.filter((item) => {
      const itemName = item.work ? item.work.toLowerCase() : '';
      const matchesQuery = itemName.includes(query.toLowerCase());
      const matchesFilter = selectedFilters.length === 0 || selectedFilters.includes(item.work);
      return matchesQuery && matchesFilter;
    });
    setFilteredItems(filtered);
  }, [items, query, selectedFilters]);

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    filterItems();
  }, [query, selectedFilters, items, filterItems]);

  const handleVoiceSearch = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Speech recognition is not supported in this browser.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.start();

    recognition.onresult = (event) => {
      let voiceResult = event.results[0][0].transcript.trim();
      setQuery(voiceResult);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };
  };

  return (
    <div className="w-screen sm:w-screen m-0 p-0 flex flex-col items-center p-4 space-y-4">
      {/* Enhanced Service Card */}
      <div className="w-full sm:w-auto bg-blue-50 shadow-lg rounded-lg p-4 max-w-md mx-auto">
  <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-center text-blue-600">
    Welcome to Our Service
  </h2>
  <p className="text-sm sm:text-base md:text-lg text-center text-gray-600 mt-2">
    If you encounter any issues, please contact us at <span className="font-semibold">S.Kumarasamy, Sedarapet, Pondy, 9751109239</span>
  </p>
</div>


      {/* Search Bar with Voice Button */}
      <div className="flex items-center justify-center w-full max-w-md">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
          className="w-3/4 p-2 pl-5 pr-12 rounded-full shadow-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="button"
          onClick={handleVoiceSearch}
          className="fixed bottom-12 right-2 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
        >
          🎤
        </button>
      </div>

      {/* Profession Buttons */}
      <div className="flex space-x-2">
        {filters.map((filter) => (
          <button
            key={filter}
            className={` w-full sm:w-auto border border-black px-2 py-2 rounded-full text-black hover:bg-gray-100 ${
              selectedFilters.includes(filter) ? 'bg-blue-500  text-red' : 'bg-blue-100  text-black'
            }`}
            onClick={() => {
              setSelectedFilters((prev) =>
                prev.includes(filter) ? prev.filter((f) => f !== filter) : [...prev, filter]
              );
            }}
          >
            {filter}
          </button>
        ))}
      </div>

      {/* Displaying Items */}
      <div className="flex flex-col items-center w-full mt-4">
        {loading ? (
          <p className="text-lg">Loading...</p>
        ) : (
          <div className="flex flex-wrap justify-center gap-4 w-full">
            {filteredItems.map((item, index) => (
              <div
                key={index}
                className="bg-white text-gray-800 rounded-lg shadow-lg p-4 w-full max-w-2xl flex justify-between items-center animate-fade-in"
              >
                <div className="flex flex-col">
                  <p className="text-lg font-bold">{item.username}</p>
                  <p className="text-sm">{item.addres}</p>
                  <p className="text-sm">{item.work}</p>
                  <a
                    href={`tel:${item.phone}`}
                    className="bg-green-500 text-white hover:bg-green-600 text-center py-2 px-2 rounded-full mt-2 transition-colors w-20 h-10"
                  >
                    Call
                  </a>
                </div>
                <img
                  src={`https://findbackend.jobsparke.com/${item.imgfile}`}
                  alt={`${item.username}'s profile`}
                  className="w-16 h-16 object-cover rounded-full"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBarWithButtons;
