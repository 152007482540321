import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import devProfileImage from './logo.png';


export default function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage the mobile menu

  return (
    <header className="w-screen sm:w-screen m-0 bg-gradient-to-r from-purple-400 to-red-500 p-4 flex justify-between items-center">
      <div className="text-white text-xl font-bold flex items-center">
        <img src={devProfileImage} alt="Logo" className="h-10 w-10 mr-1" />
        <span>Jobsparke</span>
      </div>

      {/* Buttons for larger screens */}
      <div className="hidden md:flex space-x-4">
        <button
          onClick={() => navigate('/register')}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
        >
          Register
        </button>
        <a
          href="http://service.jobsparke.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700"
        >
          Grocery List
        </a>
      </div>

      {/* Mobile menu toggle button */}
      <div className="md:hidden">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white focus:outline-none"
        >
          {isMenuOpen ? '✖️' : '☰'} {/* Toggle button for mobile */}
        </button>
      </div>

      {/* Overlay for mobile menu */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        onClick={() => setIsMenuOpen(false)} // Close menu when clicking overlay
      ></div>

      {/* Side Navigation for Mobile */}
      <nav
        className={`fixed top-0 left-0 h-full w-64 bg-gradient-to-r from-purple-400 to-red-500 transform transition-transform duration-300 ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        <div className="flex flex-col items-start p-4 space-y-4">
          <button
            onClick={() => {
              navigate('/register');
              setIsMenuOpen(false); // Close the menu after navigation
            }}
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 w-full text-left"
          >
            Register
          </button>
          <a
            href="http://service.jobsparke.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setIsMenuOpen(false)} // Close the menu after navigation
            className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 w-full text-left"
          >
            Grocery List
          </a>
        </div>
      </nav>
    </header>
  );
}
