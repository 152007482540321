// Register.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [work, setWork] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imgPreview, setImgPreview] = useState('');
  const [constitute, setconstitute] = useState('');
  const [state, setState] = useState('');
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImgFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handlePlaceChanged = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(place.formatted_address);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('imgfile', imgFile);
    formData.append('username', username);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('work', work);
    formData.append('constitute',constitute);
    formData.append('state', state);


    try {
      const response = await axios.post('https://findbackend.jobsparke.com/saveuser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('User registered successfully:', response.data);
      setMessage('User registered successfully');
      resetForm();
    } catch (error) {
      console.error('Error registering user:', error);
      setMessage('An error occurred while registering the user');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setUsername('');
    setPhone('');
    setAddress('');
    setWork('');
    setImgFile(null);
    setImgPreview('');
    setconstitute('');
    setState('');
    document.getElementById('fileInput').value = '';
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000); // Change duration here (3000ms = 3 seconds)

      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-600 to-purple-600">
      {message.includes('successfully') && (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white font-bold text-lg px-6 py-3 rounded-md shadow-lg">
          {message}
        </div>
      )}
      <div className="bg-white shadow-lg rounded-lg w-full max-w-md p-8">
        {/* Back Button */}
        <button onClick={() => navigate('/')} className="mb-4 text-gray-700 hover:text-gray-900 transition duration-200">
          <svg
            className="w-6 h-6 inline-block"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns=""
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"></path>
          </svg>
          Back
        </button>
        <h2 className="text-center text-3xl font-bold text-gray-800 mb-6">Create an Account</h2>

        <form onSubmit={handleSubmit}>  
          {/* Image Upload */}
         {/* Image Upload */}
<div className="mb-4">
  <label className="block text-gray-600 font-semibold mb-2" htmlFor="image">Upload Image</label>
  <input
    type="file"
    id="fileInput"
    name="imgfile"
    onChange={handleFileChange}
    accept="image/*"
    required
    className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
  />

  {/* Image Preview */}
  {imgPreview && (
    <div className="mt-4 relative">
      <img
        src={imgPreview}
        alt="Selected preview"
        className="w-full h-48 object-cover rounded-md border border-gray-300"
      />
      <button
        type="button"
        onClick={() => {
          setImgFile(null);
          setImgPreview('');
          document.getElementById('fileInput').value = '';
        }}
        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition duration-200"
      >
        ✕
      </button>
    </div>
  )}
</div>


          {/* Username */}
          <div className="mb-4">
            <label className="block text-gray-600 font-semibold mb-2" htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
              required
            />
          </div>

          {/* Address */}
          <div className="mb-4">
            <label className="block text-gray-600 font-semibold mb-2" htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={handleAddressChange}
              placeholder="Enter your address"
              className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
              required
            />
          </div>

          {/* Mobile Number */}
          <div className="mb-4">
            <label className="block text-gray-600 font-semibold mb-2" htmlFor="phone">Mobile Number</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your mobile number"
              className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
              required
            />
          </div>

          {/* Work */}
          <div className="mb-4">
            <label className="block text-gray-600 font-semibold mb-2" htmlFor="work">Work</label>
            <input
              type="text"
              list="work-options"
              placeholder="Enter your work"
              className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
              value={work}
              onChange={(e) => setWork(e.target.value)}

            />
             <datalist id="work-options">
              <option value="Electrician" />
              <option value="Plumber" />
              <option value="Mason" />
              <option value="Carpenter" />
            </datalist>
          </div>

          {/* Constitute */}
          <div className="mb-4">
            <label className="block text-gray-600 font-semibold mb-2" htmlFor="constitute">Constitute</label>
            <input
              type="text"
              id="constitute"
              placeholder="Enter your constitution"
              value={constitute}
              onChange={(e) => setconstitute(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
              required
            />
          </div>

          {/* State */}
          <div className="mb-4">
            <label className="block text-gray-600 font-semibold mb-2" htmlFor="state">State</label>
            <input
              type="text"
              list="state"
              placeholder="Enter your state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
                        <datalist id="state">
              <option value="TamilNadu" />
              <option value="Puducherry" />
         
            </datalist>
          </div>

          {/* Register Button */}
          <button
            type="submit"
            className="w-full py-2 text-white bg-purple-600 rounded-md hover:bg-purple-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-purple-400" disabled={loading}>
{loading ? 'Registering...' : 'Register'}          </button>
        </form>
       
      </div>
    </div>
  );
};

export default Register;
